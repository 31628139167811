import React from 'react'
import { Grid } from '@mui/material'
import useInsuranaceQuoteBlock from './useInsuranaceQuoteBlock'
import { DataGrid, GridColumns } from '@mui/x-data-grid'
const columns: GridColumns = [
  {
    field: 'quoteId',
    headerName: 'Quote Id',
    flex: 0.75,
  },
  {
    field: 'limits',
    headerName: 'Limits',
    flex: 0.5,
  },
  {
    field: 'deductible',
    headerName: 'Deductible',
    flex: 0.5,
  },
  {
    field: 'monthlyRate',
    headerName: 'Monthly Rate',
    flex: 0.5,
  },
  {
    field: 'mileageRate',
    headerName: 'Mileage Rate',
    flex: 0.5,
  },
]
const quoteColumns: GridColumns = [
  {
    field: 'quoteId',
    headerName: 'Quote Id',
    flex: 0.75,
  },
  {
    field: 'state',
    headerName: 'State',
    flex: 0.5,
  },
  {
    field: 'zip',
    headerName: 'Zip',
    flex: 0.5,
  },
  {
    field: 'vehicleMake',
    headerName: 'Make',
    flex: 0.5,
  },
  {
    field: 'vehicleModel',
    headerName: 'Model',
    flex: 0.5,
  },
  {
    field: 'vehicleVin',
    headerName: 'VIN',
    flex: 0.5,
  },
  {
    field: 'createdAt',
    headerName: 'Created At',
    flex: 0.75,
    renderCell: (params) => {
      return new Date(params.row.createdAt).toLocaleDateString()
    },
  },
]
const EMPTY_QUOTE = '00000000-0000-0000-0000-000000000000'
const InsuranceQuoteBlock = ({ subscriptionId, customerId }) => {
  const { useInsuranceQuote } = useInsuranaceQuoteBlock()
  const { insuranceQuote } = useInsuranceQuote({ subscriptionId, customerId })
  return (
    <Grid container direction="column" spacing={6}>
      <Grid item>
        {insuranceQuote &&
        insuranceQuote?.quoteId &&
        insuranceQuote?.quoteId !== '' &&
        insuranceQuote?.quoteId !== EMPTY_QUOTE ? (
          <DataGrid
            autoHeight
            columns={columns}
            rows={[insuranceQuote] || []}
            getRowId={(row) => row.quoteId}
            disableColumnFilter
          />
        ) : (
          'Quote not found.'
        )}

        {insuranceQuote &&
        insuranceQuote?.quoteId &&
        insuranceQuote?.quoteId === EMPTY_QUOTE ? (
          <LatestQuoteGroup subscriptionId={subscriptionId} />
        ) : null}
      </Grid>
    </Grid>
  )
}
const LatestQuoteGroup = ({ subscriptionId }) => {
  const { useLatestQuoteGroup } = useInsuranaceQuoteBlock()
  const { latestQuoteGroup } = useLatestQuoteGroup({
    subscriptionId,
  })

  return (
    <Grid container direction="column" spacing={6}>
      <Grid item>
        {latestQuoteGroup && latestQuoteGroup.length && (
          <DataGrid
            autoHeight
            columns={quoteColumns}
            rows={latestQuoteGroup || []}
            getRowId={(row) => row.quoteId}
            // pageSize={6}
            disableColumnFilter
          />
        )}
      </Grid>
    </Grid>
  )
}
export default InsuranceQuoteBlock
